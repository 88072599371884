import styles from "./BaseHero.module.scss"
import LetterImage from "components/ui/LetterImage"
import cn from "classnames"
import Image from "../Image"

const BaseHero = ({
  kicker,
  headline,
  subline,
  image,
  letter: letterObj,
  colors,
  readingTime,
}) => {
  const fillColorPrimary = colors[0].value

  const letter = letterObj?.value || "v"
  const hasImage = !!image
  const hasLetter = letterObj && letter !== "none"

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.noGraphic]: !hasImage && !hasLetter,
      })}
    >
      <div className={styles.textWrapper}>
        <div className={styles.text}>
          <div className={hasImage || hasLetter ? styles.textTop : ""}>
            {kicker && <p className="teaser">{kicker}</p>}
            {headline && <h1 className={styles.headline}>{headline}</h1>}
            {subline && <p className={styles.subline}>{subline}</p>}
          </div>
        </div>
      </div>
      {(hasImage || hasLetter) && (
        <div className={styles.imageContainer}>
          <div className={styles.imageWrapper}>
            {hasImage && (
              <div className={styles.image}>
                <Image
                  src={image.permalink}
                  fill
                  style={{ objectPosition: image.focus_css }}
                  sizes="(max-width: 1023px) 100vw, 50vw"
                  alt={image?.alt}
                />
              </div>
            )}
            {hasLetter && (
              <LetterImage
                type={letter}
                color={fillColorPrimary}
                className={
                  letter.toLowerCase() === "v"
                    ? styles.letterImageV
                    : styles.letterImageL
                }
              />
            )}
          </div>

          {readingTime && (
            <div className={styles.heroInfo}>
              <div className={styles.heroReadTime}>
                <h6>Lesezeit</h6>
                <p>{`${readingTime} ${
                  readingTime === 1 ? "Minute" : "Minuten"
                }`}</p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default BaseHero
