import fetchSlugs from "../utils/fetch-slugs"
import fetchContent from "../utils/fetch-content"
import fetchNav from "../utils/fetch-nav"
import { buildListQuery } from "../utils/blueprints/references"
import DynamicContent from "../components/content/DynamicContent"
import Layout from "../components/Layout"
import fetchCompany from "../utils/fetch-company"
import PageHero from "../components/PageHero"
import { createApolloClient } from "../utils/apollo"
import { replaceVariables } from "../utils/replaceVariables"

function CatchAllPage({
  content = {},
  navs = {},
  company = {},
  caseStudies = [],
}) {
  return (
    <Layout navs={navs} company={company} content={content}>
      {["home", "kontakt"].indexOf(content.slug) === -1 && (
        <PageHero data={content} />
      )}
      <DynamicContent content={content} references={caseStudies} />
    </Layout>
  )
}

// Data for current page
// export const getServerSideProps = async (context) => {
export const getStaticProps = async (context) => {
  const { previewData } = context

  const apollo = createApolloClient(previewData?.token ?? null)

  const [referencesData, content, navs, company] = await Promise.all([
    apollo.query({
      query: buildListQuery(),
    }),
    fetchContent(apollo, context),
    fetchNav(apollo, previewData?.unit),
    fetchCompany(apollo),
  ])

  if (content?.errorCode) {
    throw new Error(content.message)
  }

  if (!content) {
    return {
      notFound: true,
    }
  }

  if (referencesData.errors?.length) {
    throw new Error(referencesData.errors[0]?.message)
  }

  const references = (referencesData.data.entries.data || []).filter((e) => !!e)

  if (!references.length || !content) {
    return {
      notFound: true,
    }
  }

  const caseStudies = references

  return {
    props: {
      content: await replaceVariables(content),
      navs,
      company,
      caseStudies: await replaceVariables(caseStudies),
    },
    // revalidate: 30,
  }
}

// Generate static paths for SSR
export const getStaticPaths = async () => {
  let paths = await fetchSlugs("pages")
  paths = paths.map((path) => path.url)

  return {
    paths,
    fallback: false,
  }
}

export default CatchAllPage
