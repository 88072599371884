import { getColors } from "../../utils/color-combinations"
import BaseHero from "components/BaseHero"
import styles from "./PageHero.module.scss"

const PageHero = ({ data }) => {
  const colors = getColors(data)

  const { title, headline, subtitle, letter, title_image: image } = data

  return (
    <section className={styles.section}>
      <BaseHero
        headline={headline || title}
        subline={subtitle}
        image={image}
        colors={colors}
        letter={letter}
      />
    </section>
  )
}

export default PageHero
